<template>
  <WeContainer header="true" v-bind:actions="actions" v-if="ready">
    <WeTable
      v-bind:index="false"
      v-bind:data="feature.list"
      v-bind:columns="columns"
      v-bind:actions="tableActions"
      v-bind:multiLanguage="true"
      v-on:on-action="onAction"
      v-on:FilterLanguage="FilterLanguage"
    ></WeTable>
  </WeContainer>
  <WeLoading v-else />
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "List",
  data() {
    return {
      ready: false,
      actions: [
        {
          text: "Yeni Özellik",
          path: "features/new",
        },
        {
          text: "Çöp Kutusu",
          class: "btn btn-outline-danger float-md-right",
          icon: "fas fa-trash",
          path: "features/trash",
        },
      ],
      tableActions: [
        {
          icon: "fas fa-edit",
          class: "btn-outline-primary",
          action: "edit",
        },
        {
          icon: "fas fa-trash",
          class: "btn-outline-danger ml-2",
          action: "remove",
        },
      ],
      columns: [
        { name: "name", th: "Özellik Adı", type: "string" },
      ],
    };
  },
  methods: {
    ...mapActions("feature", ["getList", "delete"]),
    onAction(data) {
      if (data.key == "remove") {
        this.onDelete(data.row, data.index);
      } else if (data.key == "edit") {
        this.onEdit(data.row);
      }
    },
    onEdit(row) {
      this.$router.replace("features/detail/" + row.id);
    },
    onDelete(row, index) {
      let message = `${row.name} adlı özelliği silmek istediğinize emin misiniz ?`;

      this.$swal({
        title: message,
        html: `Bu işlemi <b class="text-danger">Çöp Kutusu</b> bölümünden geri alabilirsiniz.`,
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "İptal",
        confirmButtonText: "Sil",
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          const checkPermission = permission.check("feature", "d");
          if (!checkPermission) {
            permission.alert(this);
          } else {
            this.delete({
              id: row.id,
              onSuccess: (result) => {
                if (result && result.data && result.data.status !== "error") {
                  this.$toast.success("Özellik Başarıyla Silindi");
                } else {
                  this.$swal({
                    title: "Özellik Silinemedi",
                    text: result.data.message,
                    icon: "error",
                    confirmButtonText: "Tamam",
                  });
                }
              },
            });
          }
        }
      });
    },
    FilterLanguage(lang){
      this.getList({
        lang: lang,
        onSuccess: (result) => {
          this.ready = true;
        },
      });
    }
  },
  computed: {
    ...mapState(["shared", "feature","session"]),
  },
  mounted() {
    this.getList({
      onSuccess: (result) => {
        this.ready = true;
      },
    });
    let locales = this.session?.config["site.available_locales"];
    if(locales != undefined){
      locales = JSON.parse(locales)
      if(locales.length > 1) this.columns.push({ name: "locales", th: "Dil", type: "language"})
    }
  },
};
</script>
